<template>
  <section>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              Affecter une visite
              <router-link to="/visites">
                <span class="float-right btn btn-info"> retour </span>
              </router-link>
            </div>
            <div class="container">
              <form @submit.prevent="addvisite()">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="first"
                        >Nom <span class="obligatoire">*</span>
                      </label>

                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        id="first"
                        v-model="visite.nom"
                        required
                      />
                    </div>
                  </div>
                  <!--  col-md-6   -->

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="last"
                        >Délégué <span class="obligatoire">*</span></label
                      >
                      <select
                        id="inputState"
                        class="form-control"
                        v-model="visite.user_id"
                        required
                      >
                        <option
                          v-for="user in users"
                          :key="user._id"
                          v-bind:value="user._id"
                        >
                          {{ user.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="last"
                        >Produit <span class="obligatoire">*</span></label
                      >
                      <select
                        id="inputState"
                        class="form-control"
                        v-model="visite.produit_id"
                        required
                      >
                        <option
                          v-for="produit in produits"
                          :key="produit._id"
                          v-bind:value="produit._id"
                        >
                          {{ produit.designation }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!--  col-md-6   -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="last"
                        >Zone <span class="obligatoire">*</span></label
                      >
                      <select
                        id="inputState"
                        class="form-control"
                        v-model="visite.zone_id"
                        required
                      >
                        <option
                          v-for="zone in zones"
                          :key="zone._id"
                          v-bind:value="zone._id"
                        >
                          {{ zone.intitule }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!--  col-md-12  -->

                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="last"
                        >Specialité <span class="obligatoire">*</span></label
                      >
                      <select
                        class="form-control"
                        required
                        v-model="visite.specialite"
                      >
                        <option>Anatomie</option>
                        <option>Anatomie et cytologie pathologique</option>
                        <option>Anesthésie réanimation</option>
                        <option>Biologie médicale</option>
                        <option>Biologie médicale option biochimie</option>
                        <option>Biologie médicale option hématologie</option>
                        <option>Biologie médicale option immunologie</option>
                        <option>Biologie médicale option microbiologie</option>
                        <option>Biologie médicale option parasitologie</option>
                        <option>Biophysique et médecine nucléaire</option>
                        <option>Carcinologie médicale</option>
                        <option>Cardiologie</option>
                        <option>Chirurgie carcinologique</option>
                        <option>Chirurgie cardio vasculaire</option>
                        <option>Chirurgie générale</option>
                        <option>Chirurgie neurologique</option>
                        <option>
                          Chirurgie orthopédique et traumatologique
                        </option>
                        <option>Chirurgie pédiatrique</option>
                        <option>
                          Chirurgie plastique réparatrice et esthétique
                        </option>
                        <option>Chirurgie thoracique</option>
                        <option>Chirurgie urologique</option>
                        <option>Chirurgie vasculaire périphérique</option>
                        <option>Dermatologie</option>
                        <option>
                          Direction Et Logistique Médico-militaire
                        </option>
                        <option>Endocrinologie</option>
                        <option>Gastro-entérologie</option>
                        <option>Génétique</option>
                        <option>Gynécologie obstétrique</option>
                        <option>Hématologie clinique</option>
                        <option>Histo-embryologie</option>
                        <option>Hygiène Nucléaire</option>
                        <option>Imagerie médicale</option>
                        <option>Maladies infectieuses</option>
                        <option>Médecine aéronautique et spatiale</option>
                        <option>Médecine De La Plongée Sous-marine</option>
                        <option>Médecine du travail</option>
                        <option>Médecine d'urgence</option>
                        <option>Médecine générale</option>
                        <option>Médecine interne</option>
                        <option>Médecine légale</option>
                        <option>Médecine nucléaire</option>
                        <option>
                          Médecine physique, rééducation et réadaptation
                          fonctionnelle
                        </option>
                        <option>Médecine préventive et communautaire</option>
                        <option>Néphrologie</option>
                        <option>Neurologie</option>
                        <option>Nutrition et maladies nutritionnelles</option>
                        <option>Ophtalmologie</option>
                        <option>Oto-rhino-laryngologie</option>
                        <option>Pédiatrie</option>
                        <option>Pédo psychiatrie</option>
                        <option>Pharmacologie</option>
                        <option>
                          Physiologie et exploration fonctionnelle
                        </option>
                        <option>Pneumologie</option>
                        <option>Psychiatrie</option>
                        <option>Radiothérapie carcinologique</option>
                        <option>Réanimation médicale</option>
                        <option>Rhumatologie</option>
                        <option>sans spécialité</option>
                        <option>Spécialiste en médecine de famille</option>
                        <option>
                          Stomatologie et chirurgie maxillo-faciale
                        </option>
                        <option>Urologie</option>
                      </select>
                    </div>
                  </div>
                  <!--  col-md-6   -->
                </div>

                <button type="submit" class="btn btn-primary">
                  + Affecter la visite
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { HTTP } from "@/axios";
import Swal from "sweetalert2";
import Toast from "sweetalert2";
import moment from "moment";
export default {
  data() {
    return {
      visite: {
        nom: "",
        specialite:"",
        produit_id: "",
        zone_id: "",
        user_id: "",
        token : localStorage.getItem("token"),
      },
      users: [],
      zones: [],
      produits: [],
      error: "",
    };
  },

  created() {
    this.getusers();
    this.getproduits();
    this.getzones();
  },
  methods: {
    getusers() {
      HTTP.get("users/getusersdelegue")
        .then((response) => {
          console.log(response.data);
          this.users = response.data;
        })
        .then((err) => console.log(err));
    },

    getproduits() {
      HTTP.get("produits/getproduits")
        .then((response) => {
          console.log(response.data);
          this.produits = response.data;
        })
        .then((err) => console.log(err));
    },
    getzones() {
      HTTP.get("zones/getzones")
        .then((response) => {
          console.log(response.data);
          this.zones = response.data;
        })
        .then((err) => console.log(err));
    },

    addvisite() {
      HTTP.post("visites/addvisite", this.visite).then(
        (response) => {
          console.log(response);

          if (response.data.type === "erreur") {
            this.error = response.data.title;
          } else {
            Toast.fire({
              icon: "success",
              title: "visite ajouté",
            });

            this.$router.push("/visites");
          }
        },
        (err) => {
          console.log(err);
        }
      );
    },

    // getuser() {
    //   var token = localStorage.getItem("token");
    //   HTTP.get("getcurrentuser" + token)
    //     .then((response) => {
    //       this.id = response.data;
    //     })
    //     .then((err) => console.log(err));
    // },
  },
};
</script>
